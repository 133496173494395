// jQuery for page scrolling feature - requires jQuery Easing plugin
$(document).ready(function() {

    // Closes the Responsive Menu on Menu Item Click
    $('.navbar-collapse ul li a').click(function() {
        $('.navbar-toggle:visible').click();
    });

    $( 'form' ).submit( function() {
        if( this.valid != false )
        {
            //@TODO: Put this back in
            //$('input[type="submit"]').prop('disabled', true).attr( 'value', 'Processing...');
        }
    });
    
    $('.show-password-button').click(function() {
        console.log('show-password');
        var input = $(this).parent().find('input');
        var inputType = input.attr('type');
        if (inputType == 'password') {
            input.attr('type', 'text');
            $(this).removeClass('fa-eye');
            $(this).addClass('fa-eye-slash');
        } else {
            input.attr('type', 'password');
            $(this).removeClass('fa-eye-slash');
            $(this).addClass('fa-eye');
        }
    });
});
